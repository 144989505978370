// ** Logo
import logo from '@src/assets/images/logo/logo.png'
import { Fragment } from 'react'
import { useSelector } from 'react-redux'

const CustomSpinner = () => {
    const loading = useSelector((state) => state.app.loading)
    if (loading) {
        return (
            <div className='fallback-spinner app-loader'>
                <img className='fallback-logo' src={logo} alt='logo' />
                <div className='loading'>
                    <div className='effect-1 effects'></div>
                    <div className='effect-2 effects'></div>
                    <div className='effect-3 effects'></div>
                </div>
            </div>
        )
    }
    return <Fragment />
}

export default CustomSpinner
