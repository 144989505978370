// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { loadingOn, loadingOff } from '../../../../redux/app'

export const getData = createAsyncThunk('appService/getData', async (params, thunkAPI) => {
  thunkAPI.dispatch(loadingOn())
  const response = await axios.get('/service', { params }).then((response) => {
    thunkAPI.dispatch(loadingOff())
    return response
  }).catch(() => thunkAPI.dispatch(loadingOff()))
  return {
    params,
    data: response.data.data.services.map(data => {
      return { ...data, icon: <img src={data.icon} alt={data.title} width={40} /> }
    }),
    totalPages: response.data.data.total
  }
})

export const appCategorySlice = createSlice({
  name: 'appService',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export default appCategorySlice.reducer
