// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { loadingOn, loadingOff } from '../../../redux/app'

export const getData = createAsyncThunk('referral/getData', async (params, thunkAPI) => {

  thunkAPI.dispatch(loadingOn())
  const response = await axios.get(params?.Url ? params?.Url : '/referral', { params }).then((response) => {
    thunkAPI.dispatch(loadingOff())
    return response
  }).catch(() => thunkAPI.dispatch(loadingOff()))

  return {
    params,
    data: response?.data?.data?.referrals,
    totalPages: response?.data?.data?.total
  }
})
// export const passData = createAsyncThunk('qr-kits/passData', async params => {
//   return { ticket_user: params?.data }
// })


// export const profileStatusChange = createAsyncThunk('qr-kits/profileStatus', async ({ id, status, params }, thunkAPI) => {
//   await axios.post(`/users/profile/status/${id}`, { status })
//   if (params) thunkAPI.dispatch(getData({ ...params }))
// })

export const findById = createAsyncThunk('referral/findById', async id => {
  const response = await axios.get(`/qr-kit/${id}`)
  return {
    data: response.data.data
  }
})

export const statusChange = createAsyncThunk('referral/status', async ({ id, status, remark, formikHelpers, params }, thunkAPI) => {

  const response = await axios.post(`/qr-kit/${id}`, { status, remark })
  if (response.data.status_code === 200) {
    thunkAPI.dispatch(findById(id))
    formikHelpers.setSubmitting(false)
  }

  if (params) thunkAPI.dispatch(getData({ ...params }))
})

export const qrKitsSlice = createSlice({
  name: 'referral',
  initialState: {
    data: [],
    params: {},
    allData: [],
    selectedUser: null,
    qrKits_detail: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(findById.fulfilled, (state, action) => {
        state.qrKits_detail = action.payload.data
      })
    // .addCase(passData.fulfilled, (state, action) => {
    //   state.ticket_user = action?.payload?.ticket_user
    // })
  }
})
export default qrKitsSlice.reducer