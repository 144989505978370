// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { loadingOn, loadingOff } from '../../../redux/app'

export const getData = createAsyncThunk('appUsers/getData', async (params, thunkAPI) => {
  thunkAPI.dispatch(loadingOn())
  const response = await axios.get('/users', { params }).then((response) => {
    thunkAPI.dispatch(loadingOff())
    return response
  }).catch(() => thunkAPI.dispatch(loadingOff()))
  return {
    params,
    data: response.data.data.users,
    totalPages: response.data.data.total
  }
})

export const statusChange = createAsyncThunk('appUsers/status', async ({ id, status, params }, thunkAPI) => {
  await axios.post(`/users/status/${id}`, { status })
  if (params) thunkAPI.dispatch(getData({ ...params }))
})
export const dailySettlementLimitAdd = createAsyncThunk('appUsers/dailySettlementlimitadd', async ({ id, limit, balance, user, setUser, params }, thunkAPI) => {

  const res = await axios.post(`/users/change-daily-settlement-limit/${id}`, { limit, balance })
  if (res.status === 200) {
    console.log(user)
    if (balance) setUser({ ...user, daily_settlement_limit: limit, daily_settlement_balance: limit })
    else setUser({ ...user, daily_settlement_limit: limit })
  }
  if (params) thunkAPI.dispatch(getData({ ...params }))
})

export const dailySettlementStatusChange = createAsyncThunk('appUsers/dailySettlementStatus', async ({ id, status, remark, params }, thunkAPI) => {
  await axios.post(`/users/change-daily-settlement-status/${id}`, { status, remark })
  if (params) thunkAPI.dispatch(getData({ ...params }))
})

export const profileStatusChange = createAsyncThunk('appUsers/profileStatus', async ({ id, status, params }, thunkAPI) => {
  await axios.post(`/users/profile/status/${id}`, { status })
  if (params) thunkAPI.dispatch(getData({ ...params }))
})

export const findById = createAsyncThunk('appUsers/findById', async id => {
  const response = await axios.get(`/users/details/${id}`)
  return {
    data: response.data.data
  }
})
export const getCategory = createAsyncThunk('appUsers/getCategory', async params => {
  const response = await axios.get('/ticket/category', { params })
  return {
    categories: response.data.data.categories
  }
})

export const getMerchantTickets = createAsyncThunk('appUsers/getMerchantTickets', async params => {
  const response = await axios.get('/ticket', { params })
  return {
    params,
    data: response.data.data.tickets,
    totalPages: response.data.data.total
  }
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    categories: [],
    tickets: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(findById.fulfilled, (state, action) => {
        state.data = action.payload.data
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        state.categories = action.payload.categories
      })
      .addCase(getMerchantTickets.fulfilled, (state, action) => {
        state.tickets = action.payload.data
      })
  }
})
export default appUsersSlice.reducer