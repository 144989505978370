import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { loadingOn, loadingOff } from '../../../redux/app'

export const getData = createAsyncThunk('appEpos/getData', async (params, thunkAPI) => {
    thunkAPI.dispatch(loadingOn())
    const response = await axios.get('/epos/txn', { params })
        .then((response) => {
            thunkAPI.dispatch(loadingOff())
            return response
        }).catch(() => thunkAPI.dispatch(loadingOff()))
    return {
        params,
        data: response.data.data.eposTxns,
        totalPages: response.data.data.total
    }
})

export const appEposSlice = createSlice({
    name: 'appEpos',
    initialState: {
        data: [],
        total: 1,
        params: {}
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.totalPages
            })
    }
})

export default appEposSlice.reducer