import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { loadingOn, loadingOff } from '../../../redux/app'

export const getData = createAsyncThunk('appTransactions/getData', async (params, thunkAPI) => {
  thunkAPI.dispatch(loadingOn())
  const response = await axios.get('/transaction', { params })
    .then((response) => {
      thunkAPI.dispatch(loadingOff())
      return response
    }).catch(() => thunkAPI.dispatch(loadingOff()))
  return {
    params,
    data: response.data.data.transactions,
    totalPages: response.data.data.total
  }
})

export const appTransactionsSlice = createSlice({
  name: 'appTransactions',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
  }
})

export default appTransactionsSlice.reducer
