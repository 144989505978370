import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { loadingOn, loadingOff } from '../../../../redux/app'

export const list = createAsyncThunk('wish/list', async (params, thunkAPI) => {
    thunkAPI.dispatch(loadingOn())
    const response = await axios.get('/wish/category', { params })
        .then((response) => {
            thunkAPI.dispatch(loadingOff())
            return response
        })
        .catch(() => thunkAPI.dispatch(loadingOff()))
    return {
        params,
        data: response.data.data.categories,
        total: response.data.data.total
    }
})

export const appCategorySlice = createSlice({
    name: 'wish',
    initialState: {
        data: [],
        total: 1,
        params: {},
        status: null,
        start_date: "",
        end_date: "",
        page: 1,
        perPage: 10
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(list.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
            })
    }
})

export default appCategorySlice.reducer