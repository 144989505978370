// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { loadingOn, loadingOff } from '../../../../redux/app'

export const list = createAsyncThunk('appAclRole/list', async (params, thunkAPI) => {
  thunkAPI.dispatch(loadingOn())
  const response = await axios.get('/acl/role', { params }).then((response) => {
    thunkAPI.dispatch(loadingOff())
    return response
  }).catch(() => thunkAPI.dispatch(loadingOff()))
  return {
    params,
    data: response.data.data.roles,
    totalPages: response.data.data.total
  }
})

export const findById = createAsyncThunk('appAclRole/findById', async id => {
  const response = await axios.get(`/acl/role/${id}`)
  return {
    selectedRole: response.data.data.role
  }
})

export const appCategorySlice = createSlice({
  name: 'appAclRole',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedRole: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(findById.fulfilled, (state, action) => {
        state.selectedRole = action.payload.selectedRole
      })
  }
})

export default appCategorySlice.reducer
