// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { loadingOn, loadingOff } from '../../../redux/app'

export const getData = createAsyncThunk('appRewards/getData', async (params, thunkAPI) => {
  thunkAPI.dispatch(loadingOn())
  const response = await axios.get('/reward', { params }).then((response) => {
    thunkAPI.dispatch(loadingOff())
    return response
  }).catch(() => thunkAPI.dispatch(loadingOff()))
  return {
    params,
    data: response.data.data.rewords,
    totalPages: response.data.data.total
  }
})

export const statusChange = createAsyncThunk('appRewards/status', async ({ id, status, params }, thunkAPI) => {
  await axios.post(`/users/status/${id}`, { status })
  if (params) thunkAPI.dispatch(getData({ ...params }))
})

export const profileStatusChange = createAsyncThunk('appRewards/profileStatus', async ({ id, status, params }, thunkAPI) => {
  await axios.post(`/users/profile/status/${id}`, { status })
  if (params) thunkAPI.dispatch(getData({ ...params }))
})

export const findById = createAsyncThunk('appRewards/findById', async id => {
  const response = await axios.get(`/users/details/${id}`)
  return {
    data: response.data.data
  }
})
export const changeStatus = createAsyncThunk('appRewards/changeStatus', async (data) => {
  console.log(data)
  return false
  // const response = await axios.post(`/reward/7-pe-70/${data.id}`, data)
  // return {
  //   data: response.data.data
  // }
})

export const rewardSlice = createSlice({
  name: 'appRewards',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages

      })
      .addCase(findById.fulfilled, (state, action) => {
        state.data = action.payload.data
      })
      .addCase(changeStatus.fulfilled, (state, action) => {
        state.data = action.payload.data
      })
  }
})
export default rewardSlice.reducer