import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { loadingOff, loadingOn } from '../../../../redux/app'

export const list = createAsyncThunk('appWishTxn/list', async (params, thunkAPI) => {
    thunkAPI.dispatch(loadingOn())
    const response = await axios.get('/wish', { params })
        .then((response) => {
            thunkAPI.dispatch(loadingOff())
            return response
        }).catch(() => thunkAPI.dispatch(loadingOff()))
    return {
        params,
        data: response.data.data.wishTransactions,
        totalPages: response.data.data.total
    }
})

export const WishTxnSlice = createSlice({
    name: 'appWishTxn',
    initialState: {
        data: [],
        total: 1,
        params: {}
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(list.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.totalPages
            })
    }
})

export default WishTxnSlice.reducer