// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { loadingOn, loadingOff } from '../../../redux/app'

export const list = createAsyncThunk('appStaff/list', async (params, thunkAPI) => {
  thunkAPI.dispatch(loadingOn())
  const response = await axios.get('/staff', { params }).then((response) => {
    thunkAPI.dispatch(loadingOff())
    return response
  }).catch(() => thunkAPI.dispatch(loadingOff()))
  return {
    params,
    data: response.data.data.staffs,
    totalPages: response.data.data.total
  }
})

export const findById = createAsyncThunk('appStaff/findById', async id => {
  const response = await axios.get(`/staff/${id}`)
  return {
    selectedUser: response.data.data.staff
  }
})
export const roles = createAsyncThunk('appStaff/roles', async () => {
  const response = await axios.get(`acl/role/select2`)
  return {
    roles: response.data.data.roles
  }
})

export const getAdmins = createAsyncThunk('appStaff/getAdmins', async (role) => {
  const response = await axios.get(`staff/select2/${role}`)
  return {
    adminsOption: response.data.data.staffs
  }
})
export const getAssignManager = createAsyncThunk('appStaff/getAssignManager', async (role) => {
  const response = await axios.get(`staff/select2/${role}`)
  return {
    managerOption: response.data.data.staffs
  }
})
export const getAssignTeamLeader = createAsyncThunk('appStaff/getAssignTeamLeader', async (role) => {
  const response = await axios.get(`staff/select2/${role}`)
  return {
    teamLeaderOption: response.data.data.staffs
  }
})
export const getExecutive = createAsyncThunk('appStaff/getExecutive', async (role) => {
  const response = await axios.get(`staff/select2/${role}`)
  return {
    executiveOption: response.data.data.staffs
  }
})


export const appCategorySlice = createSlice({
  name: 'appStaff',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    roles: [],
    adminsOption: [],
    managerOption: [],
    teamLeaderOption: [],
    executiveOption: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(findById.fulfilled, (state, action) => {
        state.selectedUser = action.payload.selectedUser
      })
      .addCase(roles.fulfilled, (state, action) => {
        state.roles = action.payload.roles
      })
      .addCase(getAdmins.fulfilled, (state, action) => {
        state.adminsOption = action.payload.adminsOption
      })
      .addCase(getAssignManager.fulfilled, (state, action) => {
        state.managerOption = action.payload.managerOption
      })
      .addCase(getAssignTeamLeader.fulfilled, (state, action) => {
        state.teamLeaderOption = action.payload.teamLeaderOption
      })
      .addCase(getExecutive.fulfilled, (state, action) => {
        state.executiveOption = action.payload.executiveOption
      })

  }
})

export default appCategorySlice.reducer
