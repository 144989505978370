// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        loading: false
    },
    reducers: {
        loadingOn: (state) => {
            state.loading = true
        },
        loadingOff: (state) => {
            state.loading = false
        }
    }
})

export const {
    loadingOn,
    loadingOff
} = appSlice.actions

export default appSlice.reducer
