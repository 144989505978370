import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const getData = createAsyncThunk('appLog/getData', async params => {
  const response = await axios.get('/log', { params })
  return {
    params,
    data: response.data.data.logs,
    totalPages: response.data.data.total
  }
})

export const type = createAsyncThunk('appLog/type', async params => {
  const response = await axios.get('/log/type', { params })
  return {
    params,
    type: response.data.data.type
  }
})

export const appLogSlice = createSlice({
  name: 'appLog',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    type:[]
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(type.fulfilled, (state, action) => {
        state.type = action.payload.type
      })
  }
})

export default appLogSlice.reducer