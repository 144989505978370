// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { loadingOn, loadingOff } from '../../../redux/app'

export const getData = createAsyncThunk('userTickets/getData', async (params, thunkAPI) => {
  thunkAPI.dispatch(loadingOn())
  const response = await axios.get('/ticket', { params }).then((response) => {
    thunkAPI.dispatch(loadingOff())
    return response
  }).catch(() => thunkAPI.dispatch(loadingOff()))
  return {
    params,
    data: response.data.data.tickets,
    totalPages: response.data.data.total
  }
})
export const passData = createAsyncThunk('userTickets/passData', async params => {
  return { ticket_user: params?.data }
})

export const statusChange = createAsyncThunk('userTickets/status', async ({ id, status, params }, thunkAPI) => {
  await axios.post(`/users/status/${id}`, { status })
  if (params) thunkAPI.dispatch(getData({ ...params }))
})

export const profileStatusChange = createAsyncThunk('userTickets/profileStatus', async ({ id, status, params }, thunkAPI) => {
  await axios.post(`/users/profile/status/${id}`, { status })
  if (params) thunkAPI.dispatch(getData({ ...params }))
})

export const findById = createAsyncThunk('userTickets/findById', async id => {
  const response = await axios.get(`/ticket/details/${id}`)
  return {
    data: response.data.data
  }
})

export const userTicketsSlice = createSlice({
  name: 'userTickets',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    ticket_user: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(findById.fulfilled, (state, action) => {
        state.data = action.payload.data
      })
      .addCase(passData.fulfilled, (state, action) => {
        state.ticket_user = action?.payload?.ticket_user
      })
  }
})
export default userTicketsSlice.reducer