import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { loadingOn, loadingOff } from '../../../redux/app'

export const list = createAsyncThunk('appSanjeevani/list', async (params, thunkAPI) => {
    thunkAPI.dispatch(loadingOn())
    const response = await axios.get('/sanjeevani', { params })
        .then((response) => {
            thunkAPI.dispatch(loadingOff())
            return response
        }).catch(() => thunkAPI.dispatch(loadingOff()))
    return {
        params,
        data: response.data.data.rewords,
        totalPages: response.data.data.total
    }
})

export const sanjeevaniSlice = createSlice({
    name: 'appSanjeevani',
    initialState: {
        data: [],
        total: 1,
        params: {}
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(list.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.totalPages
            })
    }
})

export default sanjeevaniSlice.reducer