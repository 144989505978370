// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { loadingOn, loadingOff } from '../../../../redux/app'

export const list = createAsyncThunk('appAclPermissionGroups/list', async (params, thunkAPI) => {
  thunkAPI.dispatch(loadingOn())
  const response = await axios.get('/acl/permission-group', { params }).then((response) => {
    thunkAPI.dispatch(loadingOff())
    return response
  }).catch(() => thunkAPI.dispatch(loadingOff()))
  return {
    params,
    data: response.data.data.permissionGroups,
    totalPages: response.data.data.total
  }
})

export const pagination = createAsyncThunk('appAclPermissionGroups/pagination', async (params, thunkAPI) => {
  thunkAPI.dispatch(loadingOn())
  const response = await axios.get('/acl/permission-group/pagination', { params }).then((response) => {
    thunkAPI.dispatch(loadingOff())
    return response
  }).catch(() => thunkAPI.dispatch(loadingOff()))
  return {
    params,
    data: response.data.data.permissionGroups,
    totalPages: response.data.data.total
  }
})

export const appCategorySlice = createSlice({
  name: 'appAclPermissionGroups',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(pagination.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export default appCategorySlice.reducer
