// ** Reducers Imports
import app from './app'
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import home from '@src/views/home/store'
import users from '@src/views/user/store'
import tickets from '@src/views/ticket/store'
import transactions from '@src/views/transaction/store'
import qrCodes from '@src/views/qr-code/store'
import services from '@src/views/service/service/store'
import serviceCategories from '@src/views/service/category/store'
import serviceSubCategories from '@src/views/service/sub-category/store'
import serviceProviders from '@src/views/service/provider/store'
import aclRole from '@src/views/acl/role/store'
import aclPermissionGroup from '@src/views/acl/permission-group/store'
import aclPermission from '@src/views/acl/permission/store'
import chat from '@src/views/apps/chat/store'
import staffs from '@src/views/staff/store'
import txnRecons from '@src/views/txn-recon/store'
import logs from '@src/views/log/store'
import rewards from '@src/views/rewards/store'
import wishCategory from '@src/views/wish/Category/store'
import tags from '@src/views/tag/store'
import epos from '@src/views/epos/store'
import wish from '@src/views/wish/Txn/store'
import sanjeevani from '@src/views/sanjeevani/store'
import qrKits from '@src/views/qr-kits/store'
import referral from '@src/customComponents/referralService/store'

const rootReducer = {
  app,
  auth,
  navbar,
  layout,
  home,
  users,
  tickets,
  rewards,
  chat,
  transactions,
  qrCodes,
  services,
  serviceCategories,
  serviceSubCategories,
  serviceProviders,
  aclRole,
  aclPermissionGroup,
  aclPermission,
  staffs,
  logs,
  txnRecons,
  wishCategory,
  tags,
  epos,
  wish,
  sanjeevani,
  qrKits,
  referral
}

export default rootReducer
